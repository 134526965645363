import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button", "areaToBeDisplayed"]

  connect(){
    this.buttonTarget.addEventListener('touchend',
        this.touchend)
    this.areaToBeDisplayedTarget.style.display = "none";
  }

  click(){
    this.toggleShare()
  }

  touchend() {
    this.toggleShare()
  }

  toggleShare() {
    if(navigator.share && navigator.canShare()) {
      const shareData = {
        title: document.title,
        url: window.href,
      };
      navigator.share(shareData);
    } else {
      this.areaToBeDisplayedTarget.style.display  == "none" ? this.show() : this.hide()
    }
  }

  show() {
    this.areaToBeDisplayedTarget.style.display  = "block";
  }
  
  hide() {
    this.areaToBeDisplayedTarget.style.display = "none";
  }
}
